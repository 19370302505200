import {useNavigate} from 'react-router-dom'
import {useMutation, useQuery} from '@tanstack/react-query'
import {RESOURCE_PACKAGE_TYPE} from 'app/constants/resource-package'
import {Field, Form, Formik, useFormikContext} from 'formik'
import {buyResourcePackage, getResourcePackagePrice} from './core/_requests'
import {ORDER_TYPE} from 'app/constants/order'

const ResourcePackageOptions = {
  totalList: [
    {
      value: 5000,
      label: '5 千次',
    },
    {
      value: 1 * 10000,
      label: '1 万次',
    },
    {
      value: 10 * 10000,
      label: '10 万次',
    },
    {
      value: 50 * 10000,
      label: '50 万次',
    },
    {
      value: 100 * 10000,
      label: '100 万次',
    },
  ],
  durationList: [
    {
      value: 365 * 24 * 3600,
      label: '365 天',
    },
  ],
}

type ResourcePackageSpec = {
  type: string
  total: number
  duration: number
}

const ResourcePackagePriceCalculator = () => {
  const {values} = useFormikContext<ResourcePackageSpec>()

  const {data: priceData, isSuccess} = useQuery([values], () =>
    getResourcePackagePrice(values.type, Number(values.total), Number(values.duration))
  )
  return (
    <>
      {isSuccess ? (
        <div className='d-flex align-items-center'>
          <span className='fs-1 text-danger align-middle fw-bold'>
            {priceData.tradePrice / 100}
          </span>
          <span className='fs-5 text-danger align-middle ms-2'>元</span>
          {priceData.tradePrice !== priceData.originalPrice && (
            <span className='fs-5 text-muted text-decoration-line-through ms-2'>
              原价: {priceData.originalPrice / 100} 元
            </span>
          )}
        </div>
      ) : (
        <div className='d-flex align-items-center'>计算中</div>
      )}
    </>
  )
}

const BuyResourcePackagePage = () => {
  const Navigate = useNavigate()

  const initValues = {
    type: RESOURCE_PACKAGE_TYPE.renderImgReq.name,
    total: String(ResourcePackageOptions.totalList[0].value),
    duration: String(ResourcePackageOptions.durationList[0].value),
  }
  const buyPackageMutation = useMutation(
    (values: ResourcePackageSpec) => {
      return buyResourcePackage(values.type, values.total, values.duration)
    },
    {
      onSuccess: (data, variables, context) => {
        Navigate(`/checkout?orderType=${ORDER_TYPE.resourcePackage.name}&orderID=${data?.id}`)
      },
    }
  )

  const submit = (values: {type: string; total: string; duration: string}) => {
    console.log(values)
    buyPackageMutation.mutate({
      type: values.type,
      total: Number(values.total),
      duration: Number(values.duration),
    })
  }

  return (
    <div className='card'>
      <div className='card-body border-top p-9'>
        <Formik initialValues={initValues} onSubmit={submit}>
          {({values}) => (
            <Form>
              <div className='mb-6 fv-row'>
                <label className='d-flex align-items-center form-label mb-4 fw-bold fs-5'>
                  资源类型
                </label>
                <div className=' row row-cols-3 row-cols-lg-6 g-1 g-lg-2'>
                  {RESOURCE_PACKAGE_TYPE.values.map((typeValue) => {
                    const packageType = RESOURCE_PACKAGE_TYPE.by_value(typeValue)
                    return (
                      <div className='col' key={packageType?.name}>
                        <Field
                          type='radio'
                          className='btn-check'
                          name='type'
                          value={packageType?.name}
                          id={`kt_package_type_select_${packageType?.name}`}
                        />
                        <label
                          className='btn btn-light-primary w-100 p-4'
                          htmlFor={`kt_package_type_select_${packageType?.name}`}
                        >
                          <span className='fs-4'>{packageType?.description}</span>
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='mb-6 fv-row'>
                <label className='align-items-center form-label mb-4 fw-bold fs-5'>规格</label>
                <div className='row row-cols-3 row-cols-lg-6 g-1 g-lg-2'>
                  {ResourcePackageOptions.totalList.map((item) => {
                    return (
                      <div className='col' key={item.value}>
                        <Field
                          type='radio'
                          className='btn-check'
                          name='total'
                          value={String(item.value)}
                          id={`kt_package_size_select_${item.value}`}
                        />
                        <label
                          className='btn btn-light-primary w-100 p-4'
                          htmlFor={`kt_package_size_select_${item.value}`}
                        >
                          <span className='fs-3'>{item.label}</span>
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='mb-6 fv-row'>
                <label className='d-flex align-items-center form-label mb-4 fw-bold fs-5'>
                  有效期
                </label>
                <div className='col-lg-11 row row-cols-3 row-cols-lg-6 g-1 g-lg-2'>
                  {ResourcePackageOptions.durationList.map((item) => {
                    return (
                      <div className='col' key={item.value}>
                        <Field
                          type='radio'
                          className='btn-check'
                          name='duration'
                          value={String(item.value)}
                          id={`kt_package_duration_select_${item.value}`}
                        />
                        <label
                          className='btn btn-light-primary w-100 p-4'
                          htmlFor={`kt_package_duration_select_${item.value}`}
                        >
                          <span className='fs-4'>{item.label}</span>
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='separator border-5 my-5'></div>
              <div className='fv-row'>
                <label className='col-form-label fw-bold fs-5'>当前费用:</label>
                <ResourcePackagePriceCalculator />
                <button
                  type='submit'
                  className='btn btn-primary mt-2'
                  disabled={buyPackageMutation.isLoading}
                >
                  {buyPackageMutation.isLoading ? '请稍等...' : '立即购买'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {BuyResourcePackagePage}
