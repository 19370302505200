import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '_metronic/layout/core'
import {AppsListWrapper} from './apps-list/AppsList'
import {AppDetail} from './app-detail/AppDetail'

const appBreadCrumbs: Array<PageLink> = [
  {
    title: '应用列表',
    path: '/apps',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AppPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle>应用列表</PageTitle>
              <AppsListWrapper />
            </>
          }
        />
        <Route
          path=':appId'
          element={
            <>
              <PageTitle breadcrumbs={appBreadCrumbs}>应用详情</PageTitle>
              <AppDetail />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export {AppPage}
