import axios from 'app/utils/axios'
import {ID, Response} from '_metronic/helpers'
import {ResourcePackage, ResourcePackageOverview, ResourcePackagePrice} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PACKAGE_URL = `${API_URL}/resource-packages`

type resourcePackageListData = {
  items: Array<ResourcePackage>
  pagination: {
    next: string
  }
}

export const getResourcePackages = (query: string): Promise<resourcePackageListData> => {
  return axios
    .get(`${PACKAGE_URL}${query}`)
    .then((response: Response<resourcePackageListData>) => response.data!)
}

export const cancelResourcePackage = (package_id: ID): Promise<void> => {
  return axios.delete(`${PACKAGE_URL}/${package_id}`).then(() => {})
}

export const getResourcePackageOverview = (): Promise<ResourcePackageOverview> => {
  return axios
    .get(`${API_URL}/resource-package-overview`)
    .then((resp: Response<ResourcePackageOverview>) => resp.data!)
}

export const getResourcePackagePrice = (
  type: string,
  total: number,
  duration: number
): Promise<ResourcePackagePrice> => {
  return axios
    .get(`${API_URL}/resource-package-price`, {params: {type, total, duration}})
    .then((response: Response<ResourcePackagePrice>) => response.data!)
}

export const buyResourcePackage = (
  type: string,
  total: number,
  duration: number
): Promise<ResourcePackage | undefined> => {
  return axios
    .post(PACKAGE_URL, {type: type, total: total, duration: duration})
    .then((response: Response<ResourcePackage>) => response.data)
}
