import {useNavigate, useSearchParams} from 'react-router-dom'
import {QRCodeSVG} from 'qrcode.react'
import {KTCard, KTCardBody, KTSVG} from '_metronic/helpers'
import {ORDER_TYPE} from 'app/constants/order'
import {FC, useEffect, useState} from 'react'
import {useMutation, useQuery} from '@tanstack/react-query'
import {createPayment, getPayment} from './_requests'
import {PAYMENT_STATE, PAY_WAY} from 'app/constants/payment'
import {Payment, PaymentProps} from './_models'
import {toast} from 'react-toastify'

const WechatNativePay: FC<{qrCodeContent: string}> = ({qrCodeContent}) => {
  return (
    <div className='d-flex'>
      {/* 支付主体区 */}
      <div className='d-flex flex-column'>
        <div className='w-196 dh-196'>
          <QRCodeSVG size={196} value={qrCodeContent} />
        </div>
        <span className='bg-success text-white p-2 w-196'>请打开手机微信，扫一扫完成支付</span>
      </div>
    </div>
  )
}

const CheckoutPage = () => {
  const navigate = useNavigate()

  const [search, setSearch] = useSearchParams()
  const orderType = search.get('orderType') || ''
  const orderID = search.get('orderID') || ''

  const [payWay, setPayWay] = useState<string>(PAY_WAY.wechatNative.name)
  const [payment, setPayment] = useState<Payment>()

  const {data, error} = useQuery(['payment', payment?.id], () => getPayment(payment?.id), {
    enabled: !!payment && payment.state == PAYMENT_STATE.unpaid.name,
    refetchInterval: 3000,
    onSuccess: (data) => {
      if (data?.state == PAYMENT_STATE.paid.name) {
        toast.success('支付成功，正在跳转...')
        if (orderType == ORDER_TYPE.resourcePackage.name) {
          navigate('/packages')
        }
      }
    },
  })

  const createPaymentMutation = useMutation(
    (payWay: string) => {
      return createPayment(orderType, orderID, payWay)
    },
    {
      onSuccess: (data, variables, context) => {
        setPayment(data)
      },
    }
  )

  useEffect(() => {
    createPaymentMutation.mutate(payWay)
  }, [orderType, orderID, payWay])

  return (
    <KTCard>
      <KTCardBody>
        <div>
          <div className='fw-bold fs-1'>
            {payment ? `${payment.currencyCode} ${payment.totalFee / 100}` : '计算中'}
          </div>
          <div className='d-flex'>
            <label className='form-label fw-bold'>订单类型：</label>
            <div>{ORDER_TYPE.by_name(orderType!)?.description}</div>
          </div>
          <div className='d-flex'>
            <label className='form-label fw-bold'>订单编号：</label>
            <div>{orderID}</div>
          </div>
          <div className='separator border-5 my-5'></div>
          <div className='mb-6 fv-row'>
            <label className='d-flex align-items-center form-label mb-4 fw-bold fs-5'>
              支付方式
            </label>
            <div className='col-lg-11 row row-cols-3 row-cols-lg-6 g-1 g-lg-2'>
              <label className='d-flex align-items-center mb-6 cursor-pointer bg-light-primary p-3 rounded'>
                <span className='form-check form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='payWay'
                    value={PAY_WAY.wechatNative.name}
                    checked={payWay === PAY_WAY.wechatNative.name}
                    onChange={() => setPayWay(PAY_WAY.wechatNative.name)}
                  />
                </span>

                <span className='d-flex align-items-center'>
                  <span className='symbol-label me-1'>
                    <KTSVG
                      path='/media/icons/duotune/wechatPay.svg'
                      className='svg-icon-1 text-success'
                    />
                  </span>
                  <span className='fs-5'>微信支付</span>
                </span>
              </label>
            </div>
            {/* 支付信息区 */}
            {createPaymentMutation.isSuccess ? (
              <WechatNativePay qrCodeContent={payment?.props.codeUrl!} />
            ) : (
              <>加载中</>
            )}
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export {CheckoutPage}
