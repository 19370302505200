import {AxiosResponse} from 'axios'
import axios from 'app/utils/axios'
import {ID, Response} from '_metronic/helpers'
import {AppKey, App} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const APP_URL = `${API_URL}/apps`

export const getApps = (query?: string): Promise<Response<Array<App>>> => {
  return axios.get(`${APP_URL}?${query}`)
  // .then((response: AxiosResponse<AppsQueryResponse>) => response.data)
}

export const getAppById = (id: string | undefined): Promise<App | undefined> => {
  return axios.get(`${APP_URL}/${id}`).then((response: Response<App>) => response.data)
}

export const createApp = (app: App): Promise<App | undefined> => {
  return axios
    .post(APP_URL, app)
    .then((response: AxiosResponse<Response<App>>) => response.data)
    .then((response: Response<App>) => response.data)
}

export const updateApp = (app: App): Promise<App | undefined> => {
  return axios.patch(`${APP_URL}/${app.id}`, app).then((response: Response<App>) => response.data)
}

export const deleteApp = (appId: ID): Promise<void> => {
  return axios.delete(`${APP_URL}/${appId}`).then(() => {})
}

export const createAppKey = (appId: ID): Promise<AppKey | undefined> => {
  return axios
    .post(`${APP_URL}/${appId}/keys`)
    .then((response: AxiosResponse<Response<AppKey>>) => response.data)
    .then((response: Response<AppKey>) => response.data)
}

export const updateAppKey = (api_key: AppKey): Promise<AppKey | undefined> => {
  return axios
    .patch(`${APP_URL}/${api_key.appID}/keys/${api_key.id}`, api_key)
    .then((response: AxiosResponse<Response<AppKey>>) => response.data)
    .then((response: Response<AppKey>) => response.data)
}

export const deleteAppKey = (api_key: AppKey): Promise<void> => {
  return axios.delete(`${APP_URL}/${api_key.appID}/keys/${api_key.id}`).then(() => {})
}

export const getAppKeys = (appId: ID): Promise<Response<Array<AppKey>>> => {
  return axios.get(`${APP_URL}/${appId}/keys`)
}
