import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {toast} from 'react-toastify'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import {LayoutSplashScreen} from '_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {refreshAccessToken, getUserByToken} from './_requests'
import {WithChildren} from '_metronic/helpers'
import axios from 'app/utils/axios'
import {AxiosError} from 'axios'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    const authRefreshLogic = async (failedRequest: AxiosError) => {
      const auth = authHelper.getAuth()
      if (!auth) {
        toast.error('登录已失效，请重新登录！')
        logout()
        return
      }

      return refreshAccessToken(auth!.refreshToken).then(
        (tokenRefreshResponse) => {
          authHelper.setAuth(tokenRefreshResponse.data)
          return Promise.resolve()
        },
        (err) => {
          console.error(err)
          toast.error('登录已失效，请重新登录！')
          logout()
        }
      )
    }

    const authRefreshInterceptor = createAuthRefreshInterceptor(axios, authRefreshLogic, {
      statusCodes: [401],
      pauseInstanceWhileRefreshing: true,
    })
    return () => {
      axios.interceptors.response.eject(authRefreshInterceptor)
    }
  }, [])

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (access_token: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(access_token)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
