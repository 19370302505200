import {AppEditModalForm} from './AppEditModalForm'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

const AppEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  if (!itemIdForUpdate) {
    return <AppEditModalForm isAppLoading={false} app={{id: undefined}} />
  }

  return null
}

export {AppEditModalFormWrapper}
