/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>开放应用</span>
        </div>
      </div>
      <AsideMenuItem
        to='/apps'
        icon='/media/icons/duotune/general/gen019.svg'
        title='应用管理'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/packages'
        icon='/media/icons/duotune/general/gen017.svg'
        title='资源包管理'
        fontIcon='bi-layers'
      />
    </>
  )
}
