import {FC} from 'react'

type Props = {
  status?: string
}

const ItemStatusBadge: FC<Props> = ({status}) => (
  <>
    {status === 'enable' ? (
      <div className='badge badge-light-success fw-bolder'>已启用</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>已禁用</div>
    )}
  </>
)

export {ItemStatusBadge}
