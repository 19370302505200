import {Outlet, Routes, Route} from 'react-router-dom'
import {PageTitle} from '_metronic/layout/core'
import {PackagesListPackageWrapper} from './List'
import {BuyResourcePackagePage} from './BuyResourcePackagePage'

const ResourcePackagePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle>资源包列表</PageTitle>
              <PackagesListPackageWrapper />
            </>
          }
        />
        <Route
          path='/buy'
          element={
            <>
              <PageTitle>购买资源包</PageTitle>
              <BuyResourcePackagePage />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export {ResourcePackagePage}
