import React from 'react'
import {AppBasicSettings} from './AppBasicSettings'
import {AppKeys} from './AppKey'
import {AppDangerSettings} from './AppDangerSettings'
import {useParams} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import {getAppById} from '../core/_request'
import {getAppQueryKey} from '../core/_queries'

const AppDetail = () => {
  const {appId} = useParams()

  const {data: app, isLoading, isError} = useQuery([getAppQueryKey(appId)], () => getAppById(appId))

  return (
    <>
      {app && (
        <div className='d-flex flex-column'>
          <AppKeys app={app} />
          <AppBasicSettings app={app} />
          <AppDangerSettings app={app} />
        </div>
      )}
    </>
  )
}

export {AppDetail}
