import {Column, ColumnInstance, HeaderProps, Row} from 'react-table'
import {AppKey} from '../../core/_models'
import {AppKeyStatusCell} from './AppKeyStatusCell'
import {AppKeySecretCell} from './AppKeySecretCell'
import {AppKeyActionsCell} from './AppKeyActionsCell'
import {FC, PropsWithChildren} from 'react'
import clsx from 'clsx'
import {convertISOTimeToLocalTime} from 'app/lib/time'

type CustomHeaderColumnProps = {
  column: ColumnInstance<AppKey>
}

export const CustomHeaderColumn: FC<CustomHeaderColumnProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type CustomRowProps = {
  row: Row<AppKey>
}

export const CustomRow: FC<CustomRowProps> = ({row}) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

type CustomTableColumnHeaderProps = {
  title?: string
  className?: string
  tableProps: PropsWithChildren<HeaderProps<AppKey>>
}

const CustomTableColumnHeader: FC<CustomTableColumnHeaderProps> = ({
  title,
  className,
  tableProps,
}) => {
  return (
    <th {...tableProps.column.getHeaderProps()} className={clsx(className)}>
      {title}
    </th>
  )
}

const appKeyColumns: ReadonlyArray<Column<AppKey>> = [
  {
    Header: (props) => <CustomTableColumnHeader tableProps={props} title='APPID' />,
    accessor: 'appID',
  },
  {
    Header: (props) => (
      <CustomTableColumnHeader tableProps={props} title='密钥' className='min-w-450px' />
    ),
    id: 'secret',
    Cell: ({...props}) => (
      <AppKeySecretCell
        secret_id={props.data[props.row.index].id}
        secret_key={props.data[props.row.index].secretKey}
      />
    ),
  },
  {
    Header: (props) => (
      <CustomTableColumnHeader tableProps={props} title='状态' className='min-w-100px' />
    ),
    id: 'status',
    Cell: ({...props}) => <AppKeyStatusCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <CustomTableColumnHeader tableProps={props} title='创建时间' className='min-w-200px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => <>{convertISOTimeToLocalTime(props.data[props.row.index].createdAt)}</>,
  },
  {
    Header: (props) => (
      <CustomTableColumnHeader tableProps={props} title='更新时间' className='min-w-200px' />
    ),
    id: 'updatedAt',
    Cell: ({...props}) => <>{convertISOTimeToLocalTime(props.data[props.row.index].updatedAt)}</>,
  },
  {
    Header: (props) => (
      <CustomTableColumnHeader tableProps={props} title='操作' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <AppKeyActionsCell appKey={props.data[props.row.index]} />,
  },
]

export {appKeyColumns}
