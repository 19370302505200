import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import clsx from 'clsx'
import {AppKey, App} from '../core/_models'

type CustomTableColumnHeaderProps = {
  title?: string
  className?: string
  tableProps: PropsWithChildren<HeaderProps<App>>
}

const CustomTableColumnHeader: FC<CustomTableColumnHeaderProps> = ({
  title,
  className,
  tableProps,
}) => {
  return (
    <th {...tableProps.column.getHeaderProps()} className={clsx(className)}>
      {title}
    </th>
  )
}

export {CustomTableColumnHeader}
