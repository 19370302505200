export const getAppListQueryKey = () => {
  return 'appList'
}

export const getAppQueryKey = (appID: any) => {
  return `app:${appID}`
}

export const getAppKeyListQueryKey = (appID: any) => {
  return `app:${appID}:appKeyList`
}
