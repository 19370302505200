import {FC} from 'react'
import {Link} from 'react-router-dom'
import {App} from '../../core/_models'

type Props = {
  app: App
}

const AppIDCell: FC<Props> = ({app}) => {
  return (
    <Link to={`/apps/${app.id}`} className='text-gray-800 text-hover-primary mb-1'>
      {app.id}
    </Link>
  )
}

export {AppIDCell}
