import {useNavigate} from 'react-router-dom'
import {RESOURCE_PACKAGE_STATE} from 'app/constants/resource-package'
import {FC} from 'react'
import {ResourcePackage} from '../../core/_models'
import {ORDER_TYPE} from 'app/constants/order'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {ID} from '_metronic/helpers'
import {cancelResourcePackage} from '../../core/_requests'
import {toast} from 'react-toastify'

type ResourcePackageActionsCellProps = {
  resource_package: ResourcePackage
}

const ResourcePackageActionsCell: FC<ResourcePackageActionsCellProps> = ({resource_package}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const toCheckout = () => {
    navigate(
      `/checkout?orderType=${ORDER_TYPE.resourcePackage.name}&orderID=${resource_package.id}`
    )
  }

  const canclePackageMutation = useMutation((id: ID) => cancelResourcePackage(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['packages', resource_package.state])
      queryClient.invalidateQueries(['resource-package-overview'])
      toast.success('取消成功')
    },
  })

  return (
    <>
      {resource_package.state === RESOURCE_PACKAGE_STATE.waitingPay.name ? (
        <>
          <a className='btn btn-primary btn-sm px-2 me-2' onClick={toCheckout}>
            继续支付
          </a>
          <a
            className='btn btn-link link-secondary btn-sm px-2'
            onClick={() => canclePackageMutation.mutate(resource_package.id)}
          >
            取消
          </a>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export {ResourcePackageActionsCell}
