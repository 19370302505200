import {CustomHeaderColumn} from 'app/components/table/CustomHeaderColumn'
import {CustomRow} from 'app/components/table/CustomRow'
import React from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {ResourcePackage} from '../core/_models'
import {packagesColumns} from './columns/_columns'
import {useInfiniteQuery} from '@tanstack/react-query'
import {getResourcePackages} from '../core/_requests'

const PackagesTable: React.FC<{state: string}> = ({state}) => {
  const initQueryPageQuery = state !== 'all' ? `?state=${state}` : ''

  const {data, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery({
    queryKey: ['packages', state],
    queryFn: async ({pageParam = initQueryPageQuery}) => {
      return await getResourcePackages(pageParam)
    },
    getNextPageParam: (lastPage: any) => {
      if (!!lastPage.pagination.next) {
        const nextURL = new URL(lastPage.pagination.next)
        return nextURL.search
      }
      return undefined
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const flatData = React.useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data])

  const columns = React.useMemo(() => packagesColumns, [])
  const table = useTable({
    data: flatData,
    columns: columns,
  })

  return (
    <div className='table-responsive'>
      <table
        id='kt_table_resource_packages'
        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        {...table.getTableProps()}
      >
        <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {table.headers.map((column: ColumnInstance<ResourcePackage>) => (
              <CustomHeaderColumn key={column.id} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className='text-gray-600' {...table.getTableBodyProps()}>
          {table.rows.length > 0 ? (
            table.rows.map((row: Row<ResourcePackage>, i) => {
              table.prepareRow(row)
              return <CustomRow row={row} key={`row-${i}-${row.id}`} />
            })
          ) : (
            <tr>
              <td colSpan={10}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  没有资源包
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className='d-flex justify-content-center'>
        {hasNextPage && (
          <button
            className='btn btn-light btn-sm px-2'
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
          >
            {isFetchingNextPage ? '加载中...' : '加载更多'}
          </button>
        )}
      </div>
    </div>
  )
}

export {PackagesTable}
