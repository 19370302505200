import {FC} from 'react'
import {RESOURCE_PACKAGE_STATE} from 'app/constants/resource-package'
import clsx from 'clsx'

type Props = {
  state: string
}

const ResourcePackageStateCell: FC<Props> = ({state: state}) => {
  let badgeStyle = 'badge-white'
  if (state === RESOURCE_PACKAGE_STATE.waitingPay.name) {
    badgeStyle = 'badge-warning'
  } else if (state === RESOURCE_PACKAGE_STATE.normal.name) {
    badgeStyle = 'badge-success'
  }

  return (
    <>
      <div className={clsx('badge fw-bolder', badgeStyle)}>
        {RESOURCE_PACKAGE_STATE.by_name(state)?.description}
      </div>
    </>
  )
}

export {ResourcePackageStateCell}
