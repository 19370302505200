import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {App, initialApp, User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {AppsListLoading} from '../components/loading/AppsListLoading'
import {createApp} from '../../core/_request'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isAppLoading: boolean
  app: App
}

const editAppSchema = Yup.object().shape({
  name: Yup.string().min(1, '最少 1 个字符').max(64, '最多 64 个字符').required('应用名称是必需的'),
  description: Yup.string().max(128, '最多 128 个字符'),
})

const AppEditModalForm: FC<Props> = ({app, isAppLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [appForEdit] = useState<App>({
    ...app,
    name: app.name || initialApp.name,
    description: app.description,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: appForEdit,
    validationSchema: editAppSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        console.log(values)
        await createApp(values)
      } catch (ex) {
        console.error(ex)
      } finally {
        console.log('finally')
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_app_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_app_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_app_header'
          data-kt-scroll-wrappers='#kt_modal_add_app_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>应用名称</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='请输入应用名称，便于区分应用'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAppLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>应用描述</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='请输入应用描述'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isAppLoading}
            />
            {/* end::Input */}
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isAppLoading}
          >
            取消
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isAppLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>创建</span>
            {(formik.isSubmitting || isAppLoading) && (
              <span className='indicator-progress'>
                创建中...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isAppLoading) && <AppsListLoading />}
    </>
  )
}

export {AppEditModalForm}
