import {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh'
import axios from 'app/utils/axios'
import {AuthModel, CaptchaModel, UserModel} from './_models'
import {emailRegex} from 'app/utils/regex'
import {CAPTCHA_BIZ_TYPE, CAPTCHA_TYPE} from 'app/constants/captcha'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset_password`
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh`
export const GET_CAPTCHA_URL = `${API_URL}/captcha`

// Server should return AuthModel
export function login(account: string, password: string) {
  if (emailRegex.test(account)) {
    return axios.post<AuthModel>(
      LOGIN_URL,
      {
        email: account,
        password,
      },
      {
        skipAuthRefresh: true,
      } as AxiosAuthRefreshRequestConfig
    )
  }

  return axios.post<AuthModel>(
    LOGIN_URL,
    {
      phone: account,
      password,
    },
    {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig
  )
}

export function refreshAccessToken(refreshToken: string) {
  return axios.post<AuthModel>(REFRESH_TOKEN_URL, {refreshToken}, {
    skipAuthRefresh: true,
  } as AxiosAuthRefreshRequestConfig)
}

// Server should return AuthModel
export function register(phone: string, captcha: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    phone,
    captcha,
    password,
  })
}

export const getRegisterCaptcha = (phone: string) => {
  return axios.post<CaptchaModel>(GET_CAPTCHA_URL, {
    target: phone,
    targetType: CAPTCHA_TYPE.phone.name,
    bizType: CAPTCHA_BIZ_TYPE.register.name,
  })
}

export const getResetPasswordCaptcha = (account: string) => {
  if (emailRegex.test(account)) {
    return axios.post<CaptchaModel>(GET_CAPTCHA_URL, {
      target: account,
      targetType: CAPTCHA_TYPE.email.name,
      bizType: CAPTCHA_BIZ_TYPE.resetPassword.name,
    })
  }

  return axios.post<CaptchaModel>(GET_CAPTCHA_URL, {
    target: account,
    targetType: CAPTCHA_TYPE.phone.name,
    bizType: CAPTCHA_BIZ_TYPE.resetPassword.name,
  })
}

export function resetPassword(account: string, captcha: string, password: string) {
  if (emailRegex.test(account)) {
    return axios.post<AuthModel>(RESET_PASSWORD_URL, {
      email: account,
      captcha,
      password,
    })
  }

  return axios.post<AuthModel>(RESET_PASSWORD_URL, {
    phone: account,
    captcha,
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESS_TOKEN_URL)
}
