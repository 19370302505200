import {Link} from 'react-router-dom'
import {PackagesTable} from './table/PackagesTable'
import clsx from 'clsx'
import React from 'react'
import {RESOURCE_PACKAGE_STATE, RESOURCE_PACKAGE_TYPE} from 'app/constants/resource-package'
import {useQuery} from '@tanstack/react-query'
import {getResourcePackageOverview} from './core/_requests'
import {convertISOTimeToLocalTime} from 'app/lib/time'

const PackagesListPackageWrapper = () => {
  const [state, setState] = React.useState<string>(RESOURCE_PACKAGE_STATE.normal.name)

  const {data, isSuccess} = useQuery(['resource-package-overview'], () =>
    getResourcePackageOverview()
  )

  return (
    <>
      {/* 每月免费额度 */}
      {data?.freeResourcePackages.map((item) => {
        return (
          <div className='row g-5 g-xl-8 mb-8'>
            <div className='card card-xl-stretch'>
              {/* begin::Body */}
              <div className='card-body'>
                <div className='d-flex flex-column flex-grow-1 pr-3'>
                  <span className='text-primary fw-bold fs-4 mb-3'>
                    每月免费额度 - {RESOURCE_PACKAGE_TYPE.by_name(item.type)?.description}
                  </span>
                  <span className='fw-semibold fs-5'>总量：{item.total}</span>
                  <span className='fw-semibold fs-5'>
                    已使用：
                    <span className='fw-bold'>
                      {item.used} | {Math.floor((item.used / item.total) * 100)}%
                    </span>
                  </span>
                  <span className='fw-semibold fs-5'>
                    下一次重置时间: {convertISOTimeToLocalTime(item.expiredAt!)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div className='row g-5 g-xl-8'>
        {/* 资源包列表 */}
        <div className='card card-custom'>
          <div className='card-header overflow-auto'>
            <div className='card-toolbar'>
              <Link to='/packages/buy'>
                <button type='button' className='btn btn-primary'>
                  <i className='fas fa-solid fa-plus'></i>
                  购买资源包
                </button>
              </Link>
            </div>
            <ul
              className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
              role='tablist'
            >
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: state === RESOURCE_PACKAGE_STATE.normal.name,
                  })}
                  role='tab'
                  onClick={() => setState(RESOURCE_PACKAGE_STATE.normal.name)}
                >
                  可使用
                  {isSuccess && (
                    <span className='badge rounded-pill text-light text-bg-danger ms-2'>
                      {data?.statistics.normal}
                    </span>
                  )}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: state === RESOURCE_PACKAGE_STATE.waitingPay.name,
                  })}
                  role='tab'
                  onClick={() => setState(RESOURCE_PACKAGE_STATE.waitingPay.name)}
                >
                  待支付
                  {isSuccess && data.statistics.waitingPay > 0 && (
                    <span className='badge rounded-pill text-light text-bg-danger ms-2'>
                      {data?.statistics.waitingPay}
                    </span>
                  )}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: state === RESOURCE_PACKAGE_STATE.usedUp.name,
                  })}
                  role='tab'
                  onClick={() => setState(RESOURCE_PACKAGE_STATE.usedUp.name)}
                >
                  已耗尽
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: state === RESOURCE_PACKAGE_STATE.expired.name,
                  })}
                  role='tab'
                  onClick={() => setState(RESOURCE_PACKAGE_STATE.expired.name)}
                >
                  已过期
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {active: state === 'all'})}
                  role='tab'
                  onClick={() => setState('all')}
                >
                  全部
                </a>
              </li>
            </ul>
          </div>
          {/* end::Header */}

          <div className='card-body'>
            <PackagesTable state={state} />
          </div>
        </div>
      </div>
    </>
  )
}

export {PackagesListPackageWrapper}
