import React, {FC} from 'react'
import {toast} from 'react-toastify'
import {ID} from '_metronic/helpers'

type Props = {
  secret_id?: ID
  secret_key?: string
}

const AppKeySecretCell: FC<Props> = ({secret_id, secret_key}) => {
  const api_key = secret_id + '.' + secret_key

  const CopyAppKey = () => {
    navigator.clipboard.writeText(api_key).then(() => {
      toast.success('复制成功', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      })
    })
  }

  return (
    <div>
      <span>{api_key}</span>
      <i
        className='fas fa-thin fa-copy mx-2 cursor-pointer text-hover-primary'
        onClick={CopyAppKey}
      ></i>
    </div>
  )
}

export {AppKeySecretCell}
