import {FC} from 'react'
import {toast} from 'react-toastify'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {deleteAppKey, updateAppKey} from '../../core/_request'
import {AppKey} from '../../core/_models'
import {getAppKeyListQueryKey} from '../../core/_queries'
import {APP_KEY_STATUS} from 'app/constants/app'

type Props = {
  appKey: AppKey
}

const AppKeyActionsCell: FC<Props> = ({appKey}) => {
  const queryClient = useQueryClient()

  const updateAppKeyStatusMutation = useMutation(
    (newStatus: string) => {
      return updateAppKey({id: appKey.id, appID: appKey.appID, status: newStatus})
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([getAppKeyListQueryKey(appKey.appID)])
      },
    }
  )

  const deleteAppKeyMutation = useMutation(
    () => {
      return deleteAppKey(appKey)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([getAppKeyListQueryKey(appKey.appID)])
        toast.success('删除成功')
      },
    }
  )

  return (
    <>
      {appKey.status === APP_KEY_STATUS.ENABLE ? (
        <a
          className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-2'
          onClick={async () => await updateAppKeyStatusMutation.mutateAsync('disabled')}
        >
          {updateAppKeyStatusMutation.isLoading ? '请稍等' : '禁用'}
        </a>
      ) : (
        <>
          <a
            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-2 me-1'
            onClick={async () => await updateAppKeyStatusMutation.mutateAsync('enable')}
          >
            {updateAppKeyStatusMutation.isLoading ? '请稍等' : '启用'}
          </a>
          <a
            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-2'
            onClick={async () => await deleteAppKeyMutation.mutateAsync()}
          >
            {deleteAppKeyMutation.isLoading ? '请稍等' : '删除'}
          </a>
        </>
      )}
    </>
  )
}

export {AppKeyActionsCell}
