import {toast} from 'react-toastify'
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios'
import {getAuth} from '../modules/auth'

// axios instance
const instance = axios.create()

instance.defaults.headers.common['Accept'] = 'application/json'

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const auth = getAuth()
    if (auth && auth.accessToken) {
      config.headers!.Authorization = `Bearer ${auth.accessToken}`
    }
    return config
  },
  (err: any) => Promise.reject(err)
)

export interface ResponseDataModel {
  code: Number
  message: string
  data?: any
}

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    const res: ResponseDataModel = response.data
    return res
  },
  (err: AxiosError) => {
    if (err.response) {
      if (err.response.status === 401) {
        // Note: 鉴权失败后，退出登录需要使用 React Hook，所以在 AuthProvider 中处理
        return Promise.reject(err)
      }
      const res: ResponseDataModel = err.response.data
      if (res.message) {
        toast.error(res.message)
      }
    } else if (err.request) {
      toast.error('请求失败，请稍后重试')
    } else {
      toast.error('请求失败，请检查网络')
    }
    return Promise.reject(err)
  }
)

export default instance
