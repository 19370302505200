/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {getUserByToken, register, getRegisterCaptcha} from '../core/_requests'
import {useCountDown} from '../../../components/countdown'
import {PasswordMeterComponent} from '_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'

const initialValues = {
  phone: '',
  captcha: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  phone: Yup.string()
    .min(10, '最少 10 个字符')
    .max(11, '最长 11 个字符')
    .required('手机号是必需的'),
  captcha: Yup.string().length(6, '请输入 6 位验证码').required('验证码是必需的'),
  password: Yup.string().min(8, '最少 8 位').max(32, '最长 32 位').required('密码是必需的'),
  changepassword: Yup.string()
    .required('请确认密码')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], '密码不匹配'),
    }),
  acceptTerms: Yup.bool().required('需要同意服务条款和用户协议'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [retryCaptchaAfter, setRetryCaptchaAfter] = useCountDown(0)
  const [isGettingCaptcha, setIsGettingCaptcha] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(values.phone, values.captcha, values.password)
        saveAuth(auth)
        toast.success('注册成功，自动登录中')
        const {data: user} = await getUserByToken(auth.accessToken)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('注册失败')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const getEmailCaptcha = () => {
    const phone = formik.values.phone
    if (!phone) {
      toast.warn('请输入手机号')
      return
    }
    Yup.string()
      .required()
      .validate(phone)
      .then(() => {
        if (retryCaptchaAfter > 0) {
          toast.warn(`请 ${retryCaptchaAfter} 秒后再试`)
          return
        }
        setIsGettingCaptcha(true)

        getRegisterCaptcha(phone)
          .then((res) => {
            setRetryCaptchaAfter(res.data.retryAfter)
            toast.success('获取成功')
          })
          .finally(() => {
            setIsGettingCaptcha(false)
          })
      })
      .catch((err) => {
        toast.error('请输入手机号')
      })
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>创建账号</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            登录
          </Link>
          <Link
            to='/auth/forgot-password'
            className='link-primary fw-bolder'
            style={{marginLeft: '5px'}}
          >
            重置密码
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Email */}
      <div className='mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>手机号</label>
        <input
          placeholder='请输入手机号'
          type='text'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='required fs-6 fw-bold form-label mb-2'>验证码</label>
        <div className='row fv-row'>
          <div className='col-sm-8 mb-2'>
            <input
              placeholder='请输入 6 位验证码'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('captcha')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.captcha && formik.errors.captcha},
                {
                  'is-valid': formik.touched.captcha && !formik.errors.captcha,
                }
              )}
            />
          </div>
          <div className='col-sm-4'>
            <button
              id='kt_sign_up_get_captcha'
              className='btn btn-primary w-100'
              type='button'
              disabled={!formik.values.phone || isGettingCaptcha || retryCaptchaAfter > 0}
              onClick={getEmailCaptcha}
            >
              {retryCaptchaAfter ? `${retryCaptchaAfter} S` : '获取验证码'}
            </button>
          </div>
        </div>
        {formik.touched.captcha && formik.errors.captcha && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.captcha}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='required fs-6 fw-bold form-label mb-2'>密码</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='请输入密码'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>密码需要包含字母、数字和特殊符号</div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='required fs-6 fw-bold form-label mb-2'>确认密码</label>
        <input
          type='password'
          placeholder='请再输入一遍密码'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            我同意{' '}
            <a
              target='_blank'
              href='https://instame.feishu.cn/docx/LZVJdS7CaoS0FDxO71LcaxsQnOe'
              className='ms-1 link-primary'
            >
              服务条款与用户协议
            </a>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>注册</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              注册中... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            取消
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
