import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

const AppsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddAppModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-app-table-toolbar='base'>
      {/* begin::Add app */}
      <button type='button' className='btn btn-primary' onClick={openAddAppModal}>
        <i className='fas fa-solid fa-plus'></i>
        创建应用
      </button>
      {/* end::Add app */}
    </div>
  )
}

export {AppsListToolbar}
