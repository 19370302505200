import {FC} from 'react'

type ResourcePackagePriceCell = {
  price: number
  currency_code: string
}

const ResourcePackagePriceCell: FC<ResourcePackagePriceCell> = ({price, currency_code}) => {
  return <>{price > 0 ? `${price / 100} ${currency_code.toUpperCase()}` : '-'}</>
}

export {ResourcePackagePriceCell}
