import createEnum from 'app/lib/enums'

export const APP_STATUS = createEnum({
  ENABLE: ['enable', '已启用'],
  DISABLED: ['disabled', '已禁用'],
})

export const APP_KEY_STATUS = createEnum({
  ENABLE: ['enable', '已启用'],
  DISABLED: ['disabled', '已禁用'],
})
