import {Column} from 'react-table'
import {App} from '../../../core/_models'
import {AppStatusCell} from './AppStatusCell'
import {AppNameCell} from './AppNameCell'
import {CustomTableColumnHeader} from '../../../components/Table'
import {AppIDCell} from './AppIDCell'
import {convertISOTimeToLocalTime} from 'app/lib/time'

const appsColumns: ReadonlyArray<Column<App>> = [
  {
    Header: 'APPID',
    id: 'id',
    Cell: ({...props}) => <AppIDCell app={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomTableColumnHeader tableProps={props} title='应用名称' className='min-w-150px' />
    ),
    id: 'name',
    Cell: ({...props}) => <AppNameCell app={props.data[props.row.index]} />,
  },
  {
    Header: '状态',
    id: 'status',
    Cell: ({...props}) => <AppStatusCell status={props.data[props.row.index].status} />,
  },
  {
    Header: '创建时间',
    id: 'createdAt',
    Cell: ({...props}) => <>{convertISOTimeToLocalTime(props.data[props.row.index].createdAt)}</>,
  },
  {
    Header: '更新时间',
    id: 'updatedAt',
    Cell: ({...props}) => <>{convertISOTimeToLocalTime(props.data[props.row.index].updatedAt)}</>,
  },
]

export {appsColumns}
