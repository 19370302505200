import clsx from 'clsx'
import {FC, PropsWithChildren} from 'react'
import {ColumnInstance, HeaderProps} from 'react-table'

type Props = {
  column: ColumnInstance<any>
}

const CustomHeaderColumn: FC<Props> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type CustomTableColumnHeaderProps = {
  title?: string
  className?: string
  tableProps: PropsWithChildren<HeaderProps<any>>
}

const CustomTableColumnHeader: FC<CustomTableColumnHeaderProps> = ({
  title,
  className,
  tableProps,
}) => {
  return (
    <th {...tableProps.column.getHeaderProps()} className={clsx(className)}>
      {title}
    </th>
  )
}

export {CustomHeaderColumn, CustomTableColumnHeader}
