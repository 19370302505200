import {TypingEnum} from 'typing-enum'

// 验证码类型
export class CAPTCHA_TYPE extends TypingEnum {
  static readonly email = new CAPTCHA_TYPE(1, '邮箱')
  static readonly phone = new CAPTCHA_TYPE(2, '电话号码')
  private static _ = this.finish()
}

// 验证码业务类型
export class CAPTCHA_BIZ_TYPE extends TypingEnum {
  static readonly register = new CAPTCHA_BIZ_TYPE(1, '注册')
  static readonly resetPassword = new CAPTCHA_BIZ_TYPE(2, '重置密码')
  private static _ = this.finish()
}
