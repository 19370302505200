import {CustomTableColumnHeader} from 'app/components/table/CustomHeaderColumn'
import {
  RESOURCE_PACKAGE_SOURCE,
  RESOURCE_PACKAGE_STATE,
  RESOURCE_PACKAGE_TYPE,
} from 'app/constants/resource-package'
import {convertISOTimeToLocalTime} from 'app/lib/time'
import {Column} from 'react-table'
import {ResourcePackage} from '../../core/_models'
import {ResourcePackageStateCell} from './PackageStateCell'
import {ResourcePackageActionsCell} from './ResourcePackageActionsCell'
import {ResourcePackagePriceCell} from './ResourcePackagePriceCell'
import {ResourcePackageUseProgressCell} from './ResourcePackageUseProgressCell'

const packagesColumns: ReadonlyArray<Column<ResourcePackage>> = [
  {
    Header: '资源包 ID',
    id: 'id',
    Cell: ({...props}) => <>{props.data[props.row.index].id}</>,
  },
  {
    Header: '类型',
    id: 'type',
    Cell: ({...props}) => (
      <>{RESOURCE_PACKAGE_TYPE.by_name(props.data[props.row.index].type)?.description}</>
    ),
  },
  {
    Header: '使用情况',
    id: 'useProgress',
    Cell: ({...props}) => (
      <ResourcePackageUseProgressCell resource_package={props.data[props.row.index]} />
    ),
  },
  {
    Header: '有效期',
    id: 'duration',
    Cell: ({...props}) => <>{Math.floor(props.data[props.row.index].duration / (24 * 3600))} 天</>,
  },
  {
    Header: '生效时间',
    id: 'enabledAt',
    Cell: ({...props}) => (
      <>
        {[RESOURCE_PACKAGE_STATE.waitingPay.name, RESOURCE_PACKAGE_STATE.cancelled.name].includes(
          props.data[props.row.index].state
        )
          ? ''
          : convertISOTimeToLocalTime(props.data[props.row.index].enabledAt)}
      </>
    ),
  },
  {
    Header: '过期时间',
    id: 'expiredAt',
    Cell: ({...props}) => (
      <>
        {[RESOURCE_PACKAGE_STATE.waitingPay.name, RESOURCE_PACKAGE_STATE.cancelled.name].includes(
          props.data[props.row.index].state
        )
          ? ''
          : convertISOTimeToLocalTime(props.data[props.row.index].expiredAt)}
      </>
    ),
  },
  {
    Header: '来源',
    id: 'source',
    Cell: ({...props}) => (
      <>{RESOURCE_PACKAGE_SOURCE.by_name(props.data[props.row.index].source)?.description}</>
    ),
  },
  {
    Header: '价格',
    id: 'price',
    Cell: ({...props}) => (
      <ResourcePackagePriceCell
        price={props.data[props.row.index].price}
        currency_code={props.data[props.row.index].currencyCode}
      />
    ),
  },
  {
    Header: '状态',
    id: 'status',
    Cell: ({...props}) => <ResourcePackageStateCell state={props.data[props.row.index].state} />,
  },
  {
    Header: (props) => (
      <CustomTableColumnHeader tableProps={props} title='操作' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ResourcePackageActionsCell resource_package={props.data[props.row.index]} />
    ),
  },
]

export {packagesColumns}
