import {FC} from 'react'
import {APP_STATUS} from 'app/constants/app'

type Props = {
  status?: string
}

const AppStatusCell: FC<Props> = ({status}) => (
  <>
    {status === APP_STATUS.ENABLE ? (
      <div className='badge badge-light-success fw-bolder'>已启用</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>已禁用</div>
    )}
  </>
)

export {AppStatusCell}
