/**
 * 枚举定义工具
 * 示例：
 * const STATUS = createEnum({
 *     AUDIT_WAIT: [1, '审核中'],
 *     AUDIT_PASS: [2, '审核通过']
 * })
 * 获取枚举值：STATUS.AUDIT_WAIT
 * 获取枚举描述：STATUS.getDesc('AUDIT_WAIT')
 * 通过枚举值获取描述：STATUS.getDescFromValue(STATUS.WAIT)
 *
 */

interface EnumDefinition {
  [enumName: string]: [value: string, desc: string]
}

export default function createEnum(definition: EnumDefinition) {
  const nameToValueMap: any = {}
  const valueToDescMap: any = {}
  for (const enumName of Object.keys(definition)) {
    const [value, desc]: [string, string] = definition[enumName]
    nameToValueMap[enumName] = value
    valueToDescMap[value] = desc
  }
  return {
    ...nameToValueMap,
    getDesc(enumName: string): string {
      return (definition[enumName] && definition[enumName][1]) || ''
    },
    getDescFromValue(value: number): string {
      return valueToDescMap[value] || ''
    },
  }
}
