import {TypingEnum} from 'typing-enum'

export class RESOURCE_PACKAGE_SOURCE extends TypingEnum {
  static readonly free = new RESOURCE_PACKAGE_SOURCE(1, '免费赠送')
  static readonly onlinePurchase = new RESOURCE_PACKAGE_SOURCE(2, '在线购买')
  private static _ = this.finish()
}

export class RESOURCE_PACKAGE_TYPE extends TypingEnum {
  static readonly renderImgReq = new RESOURCE_PACKAGE_TYPE(1, '图片生成请求')
  private static _ = this.finish()
}

export class RESOURCE_PACKAGE_STATE extends TypingEnum {
  static readonly waitingPay = new RESOURCE_PACKAGE_STATE(1, '待支付')
  static readonly cancelled = new RESOURCE_PACKAGE_STATE(2, '已取消')
  static readonly normal = new RESOURCE_PACKAGE_STATE(3, '正常')
  static readonly refunded = new RESOURCE_PACKAGE_STATE(4, '已退款')
  static readonly expired = new RESOURCE_PACKAGE_STATE(5, '已过期')
  static readonly usedUp = new RESOURCE_PACKAGE_STATE(6, '已耗尽')
  private static _ = this.finish()
}
