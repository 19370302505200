import axios from 'app/utils/axios'
import {ID, Response} from '_metronic/helpers'
import {Payment} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const PAYMENTS_URL = `${API_URL}/payments`

export const createPayment = (
  orderType: string,
  orderID: ID,
  payWay: string
): Promise<Payment | undefined> => {
  return axios
    .post(`${PAYMENTS_URL}`, {
      orderType: orderType,
      orderID: orderID,
      payWay: payWay,
    })
    .then((response: Response<Payment>) => response.data)
}

export const getPayment = (id: ID): Promise<Payment | undefined> => {
  return axios.get(`${PAYMENTS_URL}/${id}`).then((response: Response<Payment>) => response.data)
}
