import {TypingEnum} from 'typing-enum'

export class PAY_WAY extends TypingEnum {
  static readonly wechatNative = new PAY_WAY(1, '微信扫码支付')
  private static _ = this.finish()
}

export class PAYMENT_STATE extends TypingEnum {
  static readonly unpaid = new PAYMENT_STATE(1, '未支付')
  static readonly paid = new PAYMENT_STATE(2, '已支付')
  static readonly closed = new PAYMENT_STATE(3, '已关闭')
  private static _ = this.finish()
}
