import {FC, useState} from 'react'
import {toast} from 'react-toastify'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {deleteApp, updateApp} from '../core/_request'
import {App} from '../core/_models'
import {ID} from '_metronic/helpers'
import {ItemStatusBadge} from '../components/ItemStatusBadge'
import {APP_STATUS} from 'app/constants/app'
import {getAppKeyListQueryKey, getAppListQueryKey, getAppQueryKey} from '../core/_queries'

const deleteNotice = (
  <>
    1. 删除操作无法撤销。
    <br />
    2. 删除应用后，你将无法访问和管理此应用。
    <br />
    3. <strong>该应用下所有 API Key 都将禁用。</strong> 你将无法通过 API 使用我们的服务。
  </>
)

const disableNotice = (
  <>
    <strong>禁用应用后，应用下的所有 API Key 都将禁用。</strong> 你将无法通过 API 使用我们的服务。
  </>
)

const confirmDeleteTip = '我已了解后果，确定删除'
const confirmDisableTip = '我已了解后果，确定禁用'

type ConfirmModalProps = {
  show: boolean
  notice: any
  confirmTip: string
  onHide: () => void
  onConfirm: () => void
}

const ConfirmModal: FC<ConfirmModalProps> = ({show, notice, confirmTip, onHide, onConfirm}) => {
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>你绝对确定吗？</Modal.Title>
        </Modal.Header>
        <Modal.Body>{notice}</Modal.Body>
        <Modal.Footer>
          <button className='btn btn-light-danger' onClick={onConfirm}>
            {confirmTip}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

type Props = {
  app: App
}

const AppDangerSettings: FC<Props> = ({app}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false)

  const updateAppStatusMutation = useMutation(
    (newStatus: string) => {
      return updateApp({id: app.id, status: newStatus})
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData([getAppQueryKey(app.id)], data)
        if (data?.status === APP_STATUS.DISABLED) {
          // 禁用应用成功后，主动刷新密钥列表
          queryClient.invalidateQueries({
            queryKey: [getAppKeyListQueryKey(app.id)],
            refetchType: 'all',
          })
        }
        queryClient.invalidateQueries({
          queryKey: [getAppListQueryKey()],
        })
        toast.success('操作成功')
      },
    }
  )

  const deleteAppMutation = useMutation((id: ID) => deleteApp(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([getAppListQueryKey(), getAppQueryKey(app.id)])
      toast.success('删除成功')
      navigate('/apps')
    },
  })

  const onClickChangeAppStatusButton = () => {
    if (app.status === APP_STATUS.ENABLE) {
      setShowConfirmDisableModal(true)
      return
    }
    updateAppStatusMutation.mutate(APP_STATUS.ENABLE)
  }

  const onClickDeleteAppButton = () => {
    if (app.status === APP_STATUS.ENABLE) {
      toast.warn('无法删除启用中的应用')
      return
    }
    setShowConfirmDeleteModal(true)
  }

  const onConfirmDisableApp = () => {
    setShowConfirmDisableModal(false)
    updateAppStatusMutation.mutate(APP_STATUS.DISABLED)
  }

  const onConfirmDeleteApp = () => {
    setShowConfirmDeleteModal(false)
    deleteAppMutation.mutate(app.id)
  }

  return (
    <>
      <div className='card border border-danger'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_app_danger'
          aria-expanded='true'
          aria-controls='kt_account_deactivate'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>危险操作</h3>
          </div>
        </div>

        <div id='kt_app_danger' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='d-flex flex-wrap align-items-center'>
              <div id='kt_app_danger_change_enable'>
                <div className='fs-6 fw-bolder mb-1'>改变应用状态</div>
                <div className='fw-bold text-gray-600'>
                  当前应用状态是
                  <ItemStatusBadge status={app.status} />
                </div>
              </div>
              <div id='kt_app_danger_change_enable_button' className='ms-auto'>
                <button
                  className={clsx(
                    'btn',
                    app.status === APP_STATUS.ENABLE ? 'btn-light-danger' : 'btn-light-primary'
                  )}
                  disabled={updateAppStatusMutation.isLoading}
                  onClick={onClickChangeAppStatusButton}
                >
                  {updateAppStatusMutation.isLoading
                    ? '请稍等'
                    : app.status === APP_STATUS.ENABLE
                    ? '禁用'
                    : '启用'}
                </button>
              </div>
            </div>
            <div className='separator separator-dashed my-6'></div>

            <div className='d-flex flex-wrap align-items-center mb-10'>
              <div id='kt_app_danger_change_enable'>
                <div className='fs-6 fw-bolder mb-1'>删除应用</div>
                <div className='fw-bold text-gray-600'>应用删除后，你将无法再访问应用。</div>
              </div>
              <div id='kt_app_danger_change_enable_button' className='ms-auto'>
                <button
                  className='btn btn-light-danger'
                  disabled={deleteAppMutation.isLoading}
                  onClick={onClickDeleteAppButton}
                >
                  {deleteAppMutation.isLoading ? '删除中' : '删除应用'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={showConfirmDeleteModal}
        notice={deleteNotice}
        confirmTip={confirmDeleteTip}
        onHide={() => setShowConfirmDeleteModal(false)}
        onConfirm={onConfirmDeleteApp}
      />
      <ConfirmModal
        show={showConfirmDisableModal}
        notice={disableNotice}
        confirmTip={confirmDisableTip}
        onHide={() => setShowConfirmDisableModal(false)}
        onConfirm={onConfirmDisableApp}
      />
    </>
  )
}

export {AppDangerSettings}
