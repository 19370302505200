import React, {FC, useMemo} from 'react'
import {toast} from 'react-toastify'
import {AppKey, App} from '../core/_models'
import {appKeyColumns, CustomHeaderColumn, CustomRow} from './app-key/colums'
import {ColumnInstance, Row, useTable} from 'react-table'
import {getAppKeyListQueryKey} from '../core/_queries'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {createAppKey, getAppKeys} from '../core/_request'
import {APP_STATUS, APP_KEY_STATUS} from '../../../constants/app'

type AppKeyTableProps = {
  appKeys: Array<AppKey>
}

const AppKeyTable: FC<AppKeyTableProps> = ({appKeys}) => {
  const data = useMemo(() => appKeys, [appKeys])
  const columns = useMemo(() => appKeyColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <div className='table-responsive'>
      <table
        id='kt_table_api_keys'
        className='table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer'
        {...getTableProps()}
      >
        <thead>
          <tr className='text-start text-muted fw-bolder gs-0'>
            {headers.map((column: ColumnInstance<AppKey>) => (
              <CustomHeaderColumn key={column.id} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className='text-gray-800' {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row<AppKey>, i) => {
              prepareRow(row)
              return <CustomRow row={row} key={`row-${i}-${row.id}`} />
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  没有可用的 API 密钥
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

type AppKeyProps = {
  app: App
}

const AppKeys: FC<AppKeyProps> = ({app}) => {
  const queryClient = useQueryClient()
  const {
    data: response,
    isLoading,
    isError,
  } = useQuery([getAppKeyListQueryKey(app.id)], () => getAppKeys(app.id))

  const addAppKeyMutation = useMutation(
    () => {
      return createAppKey(app.id).then(() => {
        toast.success('创建成功')
      })
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([getAppKeyListQueryKey(app.id)])
      },
    }
  )

  const createNewAppKey = () => {
    if (app.status !== APP_STATUS.ENABLE) {
      toast.warn('应用未启用，无法新建密钥')
      return
    }
    addAppKeyMutation.mutate()
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_app_api_keys'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>API 密钥</h3>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              disabled={addAppKeyMutation.isLoading}
              onClick={createNewAppKey}
            >
              <i className='fas fa-solid fa-plus'></i>
              {addAppKeyMutation.isLoading ? '请稍等...' : '新增密钥'}
            </button>
          </div>
        </div>
        <div id='kt_app_api_keys' className='collapse show'>
          <div className='card-body border-top p-9'>
            <AppKeyTable appKeys={response?.data || []} />
          </div>
        </div>
      </div>
    </>
  )
}

export {AppKeys}
