import {RESOURCE_PACKAGE_STATE} from 'app/constants/resource-package'
import clsx from 'clsx'
import {FC} from 'react'
import {ResourcePackage} from '../../core/_models'

type ResourcePackageUseProgressCellProps = {
  resource_package: ResourcePackage
}

const ResourcePackageUseProgressCell: FC<ResourcePackageUseProgressCellProps> = ({
  resource_package,
}) => {
  const percentage = Math.floor((resource_package.used / resource_package.total) * 100)

  let barStyle = 'bg-primary'
  if (resource_package.state !== RESOURCE_PACKAGE_STATE.normal.name) {
    barStyle = 'bg-secondary'
  }

  return (
    <div className='d-flex flex-column'>
      <div className='progress h-8px bg-light-primary mb-2'>
        <div
          className={clsx('progress-bar', barStyle)}
          role='progressbar'
          style={{width: `${percentage}%`}}
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
      <div className='d-flex justify-content-between'>
        <span>已使用: {resource_package.used}</span>
        <span>{percentage}%</span>
        <span>总: {resource_package.total}</span>
      </div>
    </div>
  )
}

export {ResourcePackageUseProgressCell}
