import React, {FC} from 'react'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {App} from '../core/_models'
import {updateApp} from '../core/_request'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {getAppListQueryKey, getAppQueryKey} from '../core/_queries'

type Props = {
  app: App
}

const editAppSchema = Yup.object().shape({
  name: Yup.string().min(1, '最少 1 个字符').max(64, '最多 64 个字符').required('应用名称是必需的'),
  description: Yup.string().max(128, '最多 128 个字符'),
})

const AppBasicSettings: FC<Props> = ({app}) => {
  const queryClient = useQueryClient()

  const createAppmutation = useMutation((app: App) => updateApp(app), {
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData([getAppQueryKey(app.id)], data)
      queryClient.invalidateQueries([getAppListQueryKey()])
      toast.success('保存成功')
    },
  })

  const formik = useFormik({
    initialValues: app,
    validationSchema: editAppSchema,
    onSubmit: (values, {setSubmitting}) => {
      setSubmitting(true)
      values.id = app.id
      createAppmutation.mutate(values)
      setSubmitting(false)
    },
  })
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_app_basic_settings'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>基础设置</h3>
          </div>
        </div>
        <div id='kt_app_basic_settings' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>应用名称</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    placeholder='请输入应用名称，便于区分应用'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>应用描述</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    placeholder='请输入应用描述'
                    {...formik.getFieldProps('description')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.description && formik.errors.description},
                      {
                        'is-valid': formik.touched.description && !formik.errors.description,
                      }
                    )}
                    name='description'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.description}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                {createAppmutation.isLoading ? '保存中... ' : '保存'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {AppBasicSettings}
