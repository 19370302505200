import {ID, Response} from '../../../../../_metronic/helpers'

export type User = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  description?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<User>>

export type App = {
  id?: ID
  name?: string
  description?: string
  status?: string
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
}

export const initialApp: App = {
  name: '',
  description: '',
}

export type AppsQueryResponse = Response<Array<App>>
